import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        Find <span>Everything</span> Reviewed on Review Rune!
      </Heading>
      <Heading variant='h3' sx={styles.running}>
        We've reviewed just about everything, but if we missed something, we'll
        be happy to review it.
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='/#posts'>
        Read Reviews
      </Button>
      <Button variant='white' as={Link} to='/contact'>
        Request Review
      </Button>
    </Box>
  </>
)
