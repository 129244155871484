import React from 'react'
import { Box,Heading } from 'theme-ui'
import MemphisPattern from '@components/MemphisPattern'
import { FaMicroblog } from 'react-icons/fa'
import HeroBoxed from '@widgets/HeroBoxed'
import Content from './Hero.Content'
import Photo from './Hero.Photo'

const styles = {
  stats: {
    display: [`none`, null, `block`],
    borderRadius: `left`,
    overflow: `hidden`,
    position: `absolute`,
    right: 0,
    bottom: 5,
    mb: -3
  },
  pattern: {
    width: `1/4`,
    opacity: 0.1,
    left: `auto`,
    right: 0,
    bottom: 0
  },
  wrapper: {
    display: `flex`,
    bg: `omegaLighter`,
    position: `relative`,
    zIndex: 2,
    p: 3,
  },
  icon: {
    display: `flex`,
    alignItems: `center`,
    mb: 0,
    mx: 3,
    svg: {
      color: `omegaDark`,
      fontSize: 7,
    },
  },
  number: {
    textAlign: `center`,
    fontWeight: `bold`,
    color: `alphaDark`,
    mx: 3,
    mb: 0,
  },
  text: {
    color: `omegaDark`,
    mb: 0,
  },
}

export default ({numbPosts}) => (
  <HeroBoxed.Wrapper>
    <HeroBoxed.LeftColumn>
      <Content />
    </HeroBoxed.LeftColumn>
    <HeroBoxed.RightColumn>
      <Photo />
    </HeroBoxed.RightColumn>
    <Box sx={styles.stats}>
    <Box sx={styles.wrapper}>
      <Heading variant='h3' sx={styles.icon}>
        <FaMicroblog />
      </Heading>
      <Heading variant='h2' sx={styles.number}>
        {numbPosts}+
        <Heading variant='h4' sx={styles.text}>
          REVIEWS
        </Heading>
      </Heading>
      <Heading variant='h2' sx={styles.number}>
        30+
        <Heading variant='h4' sx={styles.text}>
          INDUSTRIES
        </Heading>
      </Heading>
      {/* <Heading variant='h2' sx={styles.number}>
        15+
        <Heading variant='h4' sx={styles.text}>
          TUTORS
        </Heading>
      </Heading> */}
    </Box>
    </Box>
    <MemphisPattern sx={styles.pattern} />
  </HeroBoxed.Wrapper>
)
