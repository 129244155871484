import React from 'react'
import { Layout, Stack, Main, PreFooter, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Social from '@widgets/Social'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'

//import Testimonial from '@widgets/Testimonial'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Hero from '../components/Hero/Hero'
import Pagination from '@components/Pagination'
import { useBlogTags, useBlogCategories } from '@helpers-blog'

const Posts = ({ data: { paginatedPosts = {}, featuredPosts = {}, recentPosts = {} }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const tags = useBlogTags()
  const categories = useBlogCategories()
  return (
    <Layout {...props}>
      <Seo title='A Mystical Review Site' description='Find every product you need reviewed on Review Rune.' />
      <Stack>
        <Main>
          <Hero numbPosts={paginatedPosts.totalCount} />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Categories categories={categories} variant={['horizontal']} />
      </Stack>
      <Divider />
      <Stack title='Recently Published'>
        <Main id='posts'>
          <CardList
            nodes={paginatedPosts.nodes}
            variant={['horizontal', 'vertical']}
            columns={[1, 2, 3, 3]}
            omitMedia
            // omitExcerpt
            distinct
            omitAuthor
          />
        </Main>
        {/* <Sidebar> */}
        {/* <Sticky> */}

        {/* <Divider /> */}
        {/* <Tags tags={tags} /> */}
        {/* <Divider /> */}
        {/* <Social /> */}

        {/* </Sticky> */}
        {/* </Sidebar> */}
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
        {services.mailchimp && (
          <>
            <Divider />
            <NewsletterExpanded simple />
          </>
        )}
      </PreFooter>
      {/* <Stack>
        <Main>
          <Testimonial />
        </Main>
      </Stack> */}
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Posts
